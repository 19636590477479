<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Notice Board</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <!-- <v-skeleton-loader
      class="ma-3 pa-2"
      v-if="loading"
      v-bind="attrs"
      type="list-item-three-line"
    ></v-skeleton-loader> -->
    <div>
              <v-data-table
                  :headers="headers"
                  :items="list"
                  :search="search"
                >
                </v-data-table>
    </div>
  
 
   
   
    
       
  </div>
</template>


<script>
import axios from "axios";
import acceptedimage from './tp.jpg'
export default {
  data: () => ({
    cvList:[],
    cvFile:null,
    loading:false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    load: false,
    list: [],

    headers: [
      {
        text: "Notice",
        value: "notice",
      },
      {
        text: "Program",
        value: "program",
      },
      {
        text: "Year",
        value: "year",
      },
      {
        text: "Date",
        value: "date",
      },
    
    ],

  }),


  mounted() {
   this.init();
  },

  methods: {    
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    init()
    {
      
       axios
      .post("/TPOCompanyScheduling/notificationpage")
      .then((res) => {
        if (res.data.msg == "200") {
        
          this.list = res.data.list;
          console.log("list");
          console.log(this.list);
        }else {
          this.showSnackbar("#b71c1c", "Something went wrong...");
        }
      })
      .catch((error) => {
        window.console.log(error);
      });
    },
    

 
  }
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
.downbtn {
  float: right;
}
.btn-title1 {
  background-image: linear-gradient(-100deg, #6cf389, #0b9761);
  color: #fff;
}

</style>